const locationMapEls = document.querySelectorAll('.map > div')
console.log('sd;kjh')

locationMapEls.forEach((mapEl) => {
  const map = L.map(mapEl, { gestureHandling: true }).setView([mapEl.dataset.lat, mapEl.dataset.lng], 15)
  var marker = L.marker([mapEl.dataset.lat, mapEl.dataset.lng]).addTo(map)
  marker.bindPopup('<strong>' + mapEl.dataset.location + '</strong>')
  console.log(mapEl.dataset.lat, mapEl.dataset.lng)

  L.tileLayer('https://api.mapbox.com/styles/v1/zududavid/cl5qmyg7j002l14qhivcl4ghg/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoienVkdWRhdmlkIiwiYSI6ImNsMHV0dmI3MDB4Nm0zam41ZXVpaHMzYXcifQ.7ilLAbRa9azj9UC6HXZ8LQ', {
    attribution: '<a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> | <a href="https://www.mapbox.com/">Mapbox</a>',
    maxZoom: 18,
    id: 'mapbox/streets-v11',
    tileSize: 512,
    zoomOffset: -1,
    accessToken: 'pk.eyJ1IjoienVkdWRhdmlkIiwiYSI6ImNsMHV0dmI3MDB4Nm0zam41ZXVpaHMzYXcifQ.7ilLAbRa9azj9UC6HXZ8LQ'
  }).addTo(map)

})

