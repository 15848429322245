document.addEventListener('DOMContentLoaded', (e) => {
  window.location.hash.includes('#id=')
    ? document.body.classList.add('viewing-exhibition')
    : document.body.classList.remove('viewing-exhibition')
  var element = document.querySelector('#ar_integration_iframe')

  var observer = new MutationObserver(function (mutations) {
    mutations.forEach(function (mutation) {
      if (mutation.type === 'attributes') {
        window.location.hash.includes('#id=')
          ? document.body.classList.add('viewing-exhibition')
          : document.body.classList.remove('viewing-exhibition')
      }

      console.log(mutation.target)
    })
  })

  observer.observe(element, {
    attributes: true // configure it to listen to attribute changes
  })
})

// document.addEventListener('click', (e) => {
//   if (e.target.closest('#ar_integration_iframe')) {
//     window.location.hash.includes('#id=') ? document.body.classList.add('viewing-exhibition') : document.body.classList.remove('viewing-exhibition')
//   }
// })
