
const swiperEls = document.querySelectorAll('.posts-swiper')
const swipers = []

const getBreakPoints = (el, columns) => {
  if (el.classList.contains('portrait')) {
    return {
      // when window width is >= 320px
      768: {
        slidesPerView: columns >= 2 ? 2 : columns,
        spaceBetween: 24
      },
      // when window width is >= 480px
      992: {
        slidesPerView: columns >= 3 ? 3 : columns,
        spaceBetween: 32
      },
      // when window width is >= 640px
      1200: {
        slidesPerView: columns >= 4 ? 4 : columns,
        spaceBetween: 40
      }
    }
  } else {
    return {
      // when window width is >= 320px
      992: {
        slidesPerView: columns >= 2 ? 2 : columns
      },
      // when window width is >= 480px
      1500: {
        slidesPerView: columns >= 3 ? 3 : columns,
        spaceBetween: 30
      },
      // when window width is >= 640px
      1600: {
        slidesPerView: columns >= 4 ? 4 : columns
      }
    }
  }
}

swiperEls.forEach((el) => {
  const columns = parseInt(el.dataset.columns)

  swipers[el] = new Swiper(el, {
    // Optional parameters
    direction: 'horizontal',
    loop: false,
    slidesPerView: el.classList.contains('portrait') ? 1.2 : 1,
    spaceBetween: 16,

    // If we need pagination
    pagination: {
      el: '.swiper-pagination'
    },

    // Navigation arrows
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },

    // And if we need scrollbar
    scrollbar: {
      el: '.swiper-scrollbar'
    },

    breakpoints: getBreakPoints(el, columns)
  })
  el.querySelector('.d-none').classList.remove('d-none')
})


const hiddenContentHover = document.querySelectorAll('.hidden-content-hover')
if (hiddenContentHover) {
  hiddenContentHover.forEach((el) => {
    const p = el.querySelector('p')
    if (p) {
      const content = el.querySelector('.content')
      const height = p.scrollHeight
  
      el.addEventListener('mouseover', (e) => {
        content.style.bottom = (height + 8) + 'px'
        p.style.opacity = 1
      })
      el.addEventListener('mouseout', (e) => {
        content.style.bottom = '0px'
        p.style.opacity = 0
      })
    }
  })
}

